<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('服务管理.轮播图管理.标题')" prop="title">
                <a-input v-model="queryParam.title" :placeholder="$t('服务管理.轮播图管理.请输入标题')" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item :label="$t('服务管理.轮播图管理.位置')" prop="position">
                <a-select v-model="queryParam.position" :placeholder="$t('服务管理.轮播图管理.请选择位置')">
                  <a-select-option :value="1">{{ $t('服务管理.轮播图管理.首页') }}</a-select-option>
                  <a-select-option :value="2">{{ $t('服务管理.轮播图管理.服务') }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="位置,1-首页,2-服务" prop="position">
                  <a-select v-model="queryParam.position" :placeholder="$t('服务管理.轮播图管理.请选择位置')">
                    <a-select-option :value="1">首页</a-select-option>
                    <a-select-option :value="2">服务</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="跳转地址" prop="url">
                  <a-input v-model="queryParam.url" placeholder="请输入跳转地址" allow-clear />
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />{{ $t('通用.查询') }}</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"
                  ><a-icon type="redo" />{{ $t('通用.重置') }}</a-button
                >
                <!--                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>-->
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['banner:banner:add']">
          <a-icon type="plus" />{{ $t('通用.新增') }}
        </a-button>
        <a-button
          type="primary"
          :disabled="single"
          @click="$refs.createForm.handleUpdate(undefined, ids)"
          v-hasPermi="['banner:banner:edit']"
        >
          <a-icon type="edit" />{{ $t('通用.修改') }}
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['banner:banner:remove']">
          <a-icon type="delete" />{{ $t('通用.删除') }}
        </a-button>
        <!--        <a-button type="primary" @click="handleExport" v-hasPermi="['banner:banner:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{ float: 'right' }"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList"
        />
      </div>
      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="image" slot-scope="text, record">
          <img :src="record.image" v-viewer class="image" style="width: 58px; height: 58px; border-radius: 6px" />
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['banner:banner:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['banner:banner:edit']">
            <a-icon type="edit" />{{$t('通用.修改')}}
          </a>
          <a-divider type="vertical" v-hasPermi="['banner:banner:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['banner:banner:remove']"> <a-icon type="delete" />{{ $t('通用.删除') }} </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { pageBanner, listBanner, delBanner } from '@/api/banner/banner'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'

export default {
  name: 'Banner',
  components: {
    CreateForm,
  },
  mixins: [tableMixin],
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        title: null,
        image: null,
        position: null,
        type: null,
        url: null,
        pageNum: 1,
        pageSize: 10,
      },
      columns: [
        {
          title: '编号',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '标题',
          dataIndex: 'title',
          ellipsis: true,
          align: 'center',
        },
        {
          title: '图片',
          dataIndex: 'image',
          ellipsis: true,
          align: 'center',
          scopedSlots: { customRender: 'image' },
        },
        {
          title: '位置',
          dataIndex: 'position',
          ellipsis: true,
          align: 'center',
          customRender: function (e) {
            if (e === 1) {
              return '首页'
            } else if (e === 2) {
              return '服务'
            }
          },
        },
        {
          title: '类型',
          dataIndex: 'type',
          ellipsis: true,
          align: 'center',
          customRender: function (e) {
            if (e === 1) {
              return '后巷屋详情'
            } else if (e === 2) {
              return '服务者详情'
            } else if (e === 3) {
              return '新闻详情'
            } else if (e === 4) {
              return '公告详情'
            }
          },
        },
        /*{
          title: '跳转地址',
          dataIndex: 'url',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '添加时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center',
        },
        /*{
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center',
        },
      ],
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {
    // 监听语言变化，语言可能存储在 Vuex 或 localStorage 中
    '$i18n.locale'(newLocale) {
      this.updateColumns() // 当语言变化时更新列配置
    },
  },
  methods: {
    /** 查询轮播图列表 */
    getList() {
      this.loading = true
      pageBanner(this.queryParam).then((response) => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        title: undefined,
        image: undefined,
        position: undefined,
        type: undefined,
        url: undefined,
        pageNum: 1,
        pageSize: 10,
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      if (row.id) {
        this.ids.push(row.id)
      }
      this.$confirm({
        title: this.$t('服务管理.服务内容管理.确认删除所选中数据'),
        content:  this.$t('服务管理.服务内容管理.当前选中编号为') + ids ,
        okText: this.$t('通用.确认') ,
        cancelText: this.$t('通用.取消') ,
        onOk() {
          let data = []
          that.ids.map((id) => data.push({ id: id }))
          return delBanner(data).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {
          //取消清空删除ids列表
          that.ids = []
        },
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          that.download(
            'banner/banner/export',
            {
              ...that.queryParam,
            },
            `轮播图_${new Date().getTime()}.xlsx`
          )
        },
        onCancel() {},
      })
    },
    updateColumns() {
      // 根据当前语言获取表格列配置
      const currentLocale = this.$i18n.locale
      const columnsConfig = {
        en: [
          {
            title: 'ID',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'title',
            dataIndex: 'title',
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Image',
            dataIndex: 'image',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'image' },
          },
          {
            title: 'position',
            dataIndex: 'position',
            ellipsis: true,
            align: 'center',
            customRender: function (e) {
              if (e === 1) {
                return '首页'
              } else if (e === 2) {
                return '服务'
              }
            },
          },
          {
            title: 'Type',
            dataIndex: 'type',
            ellipsis: true,
            align: 'center',
            customRender: function (e) {
              if (e === 1) {
                return '后巷屋详情'
              } else if (e === 2) {
                return '服务者详情'
              } else if (e === 3) {
                return '新闻详情'
              } else if (e === 4) {
                return '公告详情'
              }
            },
          },
          /*{
          title: '跳转地址',
          dataIndex: 'url',
          ellipsis: true,
          align: 'center'
        },*/
          {
            title: 'createTime',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },
          {
            title: 'Operation',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
        'zh-CN': [
          {
            title: '编号',
            dataIndex: 'id',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '标题',
            dataIndex: 'title',
            ellipsis: true,
            align: 'center',
          },
          {
            title: '图片',
            dataIndex: 'image',
            ellipsis: true,
            align: 'center',
            scopedSlots: { customRender: 'image' },
          },
          {
            title: '位置',
            dataIndex: 'position',
            ellipsis: true,
            align: 'center',
            customRender: function (e) {
              if (e === 1) {
                return '首页'
              } else if (e === 2) {
                return '服务'
              }
            },
          },
          {
            title: '类型',
            dataIndex: 'type',
            ellipsis: true,
            align: 'center',
            customRender: function (e) {
              if (e === 1) {
                return '后巷屋详情'
              } else if (e === 2) {
                return '服务者详情'
              } else if (e === 3) {
                return '新闻详情'
              } else if (e === 4) {
                return '公告详情'
              }
            },
          },
          /*{
          title: '跳转地址',
          dataIndex: 'url',
          ellipsis: true,
          align: 'center'
        },*/
          {
            title: '添加时间',
            dataIndex: 'createTime',
            scopedSlots: { customRender: 'createTime' },
            ellipsis: true,
            align: 'center',
          },
          /*{
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
          {
            title: '操作',
            dataIndex: 'operation',
            width: '18%',
            scopedSlots: { customRender: 'operation' },
            align: 'center',
          },
        ],
      }
      this.columns = columnsConfig[currentLocale] || columnsConfig['en']
    },
  },
}
</script>
